// import React, { useState } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";
// import Modal from "react-modal";
// Modal.setAppElement("#root");
// const Productdetail = () => {
//   const thumbnails = [
//     "assets/img/product/big-product/product1.png",
//     "assets/img/product/small-product/product2.png",
//     "assets/img/product/small-product/product3.png",
//     "assets/img/product/small-product/product4.png",
//   ];
//   const [mainImage, setMainImage] = useState(thumbnails[0]);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const handleThumbnailClick = (src, index) => {
//     setMainImage(src);
//     setCurrentIndex(index);
//     setModalIsOpen(true);
//   };

//   const handleCloseModal = () => {
//     setModalIsOpen(false);
//   };

//   const handleNextImage = () => {
//     const nextIndex = (currentIndex + 1) % thumbnails.length;
//     setMainImage(thumbnails[nextIndex]);
//     setCurrentIndex(nextIndex);
//   };

//   const handlePreviousImage = () => {
//     const prevIndex =
//       (currentIndex - 1 + thumbnails.length) % thumbnails.length;
//     setMainImage(thumbnails[prevIndex]);
//     setCurrentIndex(prevIndex);
//   };

//   const settings = {
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     arrows: true,
//     dots: false,
//     autoplay: true,
//     speed: 300,
//     infinite: true,
//     responsive: [
//       { breakpoint: 992, settings: { slidesToShow: 3 } },
//       { breakpoint: 768, settings: { slidesToShow: 2 } },
//       { breakpoint: 300, settings: { slidesToShow: 1 } },
//     ],
//   };

//   const products = [
//     {
//       id: 1,
//       name: "Basic Joggin Shorts",
//       image: "assets/img/product/product1.jpg",
//       price: "₹300",
//       oldPrice: "₹500",
//       rating: 4,
//       reviews: 4,
//     },
//     {
//       id: 2,
//       name: "Make Thing Happen T-Shirts",
//       image: "assets/img/product/product2.jpg",
//       price: "₹300",

//       rating: 5,
//       reviews: 6,
//       discount: "-20%",
//     },
//     {
//       id: 3,
//       name: "Basic White Simple Sneaker",
//       image: "assets/img/product/product3.jpg",
//       price: "₹300",
//       oldPrice: "₹500",
//       rating: 4,
//       reviews: 2,
//       discount: "-18%",
//     },
//     {
//       id: 4,
//       name: "Basic White Simple Sneaker",
//       image: "assets/img/product/product2.jpg",
//       price: "₹300",
//       oldPrice: "₹500",
//       rating: 4,
//       reviews: 2,
//       discount: "-18%",
//     },
//   ];
//   return (
//     <div>
//       <nav aria-label="breadcrumb" id="headerspace">
//         <ol className="breadcrumb">
//           <li className="breadcrumb-item">
//             <a href="/">Home</a>
//           </li>
//           <li className="breadcrumb-item active" aria-current="page">
//             Product detail
//           </li>
//         </ol>
//       </nav>

//       <section class="product_details mb-135">
//         <div class="container">
//           <div class="row">
//             {/* <div class="col-lg-6 col-md-6">
//               <div class="product_zoom_gallery">
//                 <div class="zoom_gallery_inner d-flex">
//                   <div className="zoom_tab_img">
//                     {thumbnails.map((thumbnail, index) => (
//                       <a
//                         key={index}
//                         className="zoom_tabimg_list"
//                         href="javascript:void(0)"
//                         onClick={() => handleThumbnailClick(thumbnail)}
//                       >
//                         <img src={thumbnail} alt="tab-thumb" />
//                       </a>
//                     ))}
//                   </div>

//                   <div className="product_zoom_main_img">
//                     <div className="product_zoom_thumb">
//                       <img
//                         src={mainImage}
//                         alt=""
//                         data-image={mainImage}
//                         style={{ maxWidth: "100%", height: "600px" }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div> */}
//             <div className="col-lg-6 col-md-6">
//               <div className="product_zoom_gallery">
//                 <div className="zoom_gallery_inner d-flex">
//                   <div className="zoom_tab_img">
//                     {thumbnails.map((thumbnail, index) => (
//                       <a
//                         key={index}
//                         className="zoom_tabimg_list"
//                         href="javascript:void(0)"
//                         onClick={() => handleThumbnailClick(thumbnail, index)}
//                       >
//                         <img src={thumbnail} alt="tab-thumb" />
//                       </a>
//                     ))}
//                   </div>

//                   <div className="product_zoom_main_img">
//                     <div className="product_zoom_thumb">
//                       <img
//                         src={mainImage}
//                         alt=""
//                         data-image={mainImage}
//                         style={{ maxWidth: "100%", height: "600px" }}
//                         onClick={() =>
//                           handleThumbnailClick(
//                             mainImage,
//                             thumbnails.indexOf(mainImage)
//                           )
//                         }
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col-lg-6 col-md-6">
//               <div class="product_d_right">
//                 <form action="#">
//                   <h1>Edwin T-Shirt Logo Script Print in Navy</h1>
//                   <div class="product_ratting_review d-flex align-items-center">
//                     <div class=" product_ratting">
//                       <ul class="d-flex">
//                         <li>
//                           <a href="#">
//                             <i class="ion-ios-star"></i>
//                           </a>
//                         </li>
//                         <li>
//                           <a href="#">
//                             <i class="ion-ios-star"></i>
//                           </a>
//                         </li>
//                         <li>
//                           <a href="#">
//                             <i class="ion-ios-star"></i>
//                           </a>
//                         </li>
//                         <li>
//                           <a href="#">
//                             <i class="ion-ios-star"></i>
//                           </a>
//                         </li>
//                         <li>
//                           <a href="#">
//                             <i class="ion-ios-star"></i>
//                           </a>
//                         </li>
//                       </ul>
//                     </div>
//                     <div class="product_review">
//                       <ul class="d-flex">
//                         <li>4 reviews</li>
//                         <li>Write your review</li>
//                       </ul>
//                     </div>
//                   </div>
//                   <div class="price_box">
//                     <span class="current_price">₹300</span>
//                   </div>
//                   <div class="product_availalbe">
//                     <ul class="d-flex">
//                       <li>
//                         <i class="icon-layers icons"></i> Only <span>15</span>{" "}
//                         left{" "}
//                       </li>
//                       <li>
//                         Availalbe: <span class="stock">In Stock</span>
//                       </li>
//                     </ul>
//                   </div>
//                   <div class="product_desc">
//                     <p>
//                       A t-shirt that comes in three colors (red, white and blue)
//                       and three sizes (small, medium, large) is a configurable
//                       product.{" "}
//                     </p>
//                   </div>
//                   <div class="product_variant">
//                     <div class="filter__list widget_color d-flex align-items-center">
//                       <h3>select color</h3>
//                       <ul>
//                         <li>
//                           <input type="checkbox" />
//                           <span class="checkmark color1"></span>
//                         </li>
//                         <li>
//                           <input type="checkbox" />
//                           <span class="checkmark color2"></span>
//                         </li>
//                         <li>
//                           <input type="checkbox" />
//                           <span class="checkmark color3"></span>
//                         </li>
//                         <li>
//                           <input type="checkbox" />
//                           <span class="checkmark color5"></span>
//                         </li>
//                       </ul>
//                     </div>
//                     <div class="filter__list widget_size d-flex align-items-center">
//                       <h3>select size</h3>
//                       <ul>
//                         <li>
//                           <a href="javascript:void(0)">S </a>
//                         </li>
//                         <li>
//                           <a href="javascript:void(0)"> M</a>
//                         </li>
//                         <li>
//                           <a href="javascript:void(0)">L</a>
//                         </li>
//                         <li>
//                           <a href="javascript:void(0)"> XL</a>
//                         </li>
//                         <li>
//                           <a href="javascript:void(0)">XLL</a>
//                         </li>
//                       </ul>
//                     </div>

//                     <div class="variant_quantity_btn d-flex">
//                       <div class="pro-qty border">
//                         <input min="1" max="100" type="tex" value="1" />
//                       </div>
//                       <Link to="/Cart">
//                         <button class="button btn btn-primary" type="submit">
//                           <i class="ion-android-add"></i> Add To Cart
//                         </button>
//                       </Link>
//                       <a class="wishlist" href="#">
//                         <i class="ion-ios-heart"></i>
//                       </a>
//                     </div>
//                   </div>
//                   <div class="product_sku">
//                     <p>
//                       <span>SKU: </span> #ABC123456
//                     </p>
//                   </div>
//                   <div class="product_tags d-flex">
//                     <span>tags: </span>
//                     <ul class="d-flex">
//                       <li>
//                         <a href="#">fashion,</a>
//                       </li>
//                       <li>
//                         <a href="#">clothings,</a>
//                       </li>
//                       <li>
//                         <a href="#">accessorires</a>
//                       </li>
//                     </ul>
//                   </div>
//                   <div class="priduct_social d-flex">
//                     <span>SHARE: </span>
//                     <ul>
//                       <li>
//                         <a href="#">
//                           <i class="ion-social-twitter"></i>
//                         </a>
//                       </li>
//                       <li>
//                         <a href="#">
//                           <i class="ion-social-facebook"></i>
//                         </a>
//                       </li>
//                       <li>
//                         <a href="#">
//                           <i class="ion-social-googleplus-outline"></i>
//                         </a>
//                       </li>
//                       <li>
//                         <a href="#">
//                           <i class="ion-social-pinterest"></i>
//                         </a>
//                       </li>
//                       <li>
//                         <a href="#">
//                           <i class="ion-social-instagram-outline"></i>
//                         </a>
//                       </li>
//                     </ul>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <section className="product_area related_products mb-118">
//         <div className="container">
//           <div className="row">
//             <div className="col-12">
//               <div className="section_title mb-50">
//                 <h2>Related Products</h2>
//               </div>
//             </div>
//           </div>
//           <div className="product_container row">
//             <Slider {...settings}>
//               {products.map((product) => (
//                 <div className="col-lg-3" key={product.id}>
//                   <article className="single_product">
//                     <figure>
//                       <div className="product_thumb">
//                         <a href="product-details.html">
//                           <img
//                             className="primary_img"
//                             src={product.image}
//                             alt={product.name}
//                           />
//                         </a>
//                         <div className="product_action">
//                           <ul>
//                             <li className="wishlist">
//                               <Link to="/Favourite" title="Wishlist">
//                                 <i className="icon-heart icons"></i>
//                               </Link>
//                             </li>
//                             <li className="quick_view">
//                               <a
//                                 data-toggle="modal"
//                                 data-target="#modal_box"
//                                 title="Quick View"
//                                 href="#"
//                               >
//                                 <i className="icon-size-fullscreen icons"></i>
//                               </a>
//                             </li>
//                             <li className="compare">
//                               <Link to="/product" title="Add Cart">
//                                 <i className="icon-basket-loaded icons"></i>
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                         {product.discount && (
//                           <div className="product_label">
//                             <span>{product.discount}</span>
//                           </div>
//                         )}
//                       </div>
//                       <figcaption className="product_content text-center">
//                         <div className="product_ratting">
//                           <ul className="d-flex justify-content-center">
//                             {[...Array(product.rating)].map((_, i) => (
//                               <li key={i}>
//                                 <a href="#">
//                                   <i className="ion-android-star"></i>
//                                 </a>
//                               </li>
//                             ))}
//                             <li>
//                               <span>({product.reviews})</span>
//                             </li>
//                           </ul>
//                         </div>
//                         <h4 className="product_name">
//                           <a href="product-details.html">{product.name}</a>
//                         </h4>
//                         <div className="price_box">
//                           <span className="current_price">{product.price}</span>
//                           {product.oldPrice && (
//                             <span className="old_price">
//                               {product.oldPrice}
//                             </span>
//                           )}
//                         </div>
//                         <div className="add_to_cart">
//                           <a
//                             className="btn btn-primary"
//                             href="#"
//                             title="Add To Cart"
//                           >
//                             Add To Cart
//                           </a>
//                         </div>
//                       </figcaption>
//                     </figure>
//                   </article>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       </section>
//       <div
//         className="modal fade"
//         id="modal_box"
//         tabIndex="-1"
//         role="dialog"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered" role="document">
//           <div className="modal-content">
//             <button
//               type="button"
//               className="close"
//               data-dismiss="modal"
//               aria-label="Close"
//             >
//               <span aria-hidden="true">
//                 <i className="ion-android-close"></i>
//               </span>
//             </button>
//             <div className="modal_body">
//               <div className="container">
//                 <div className="row">
//                   <div className="col-lg-6 col-md-6 col-sm-12">
//                     <div className="modal_zoom_gallery">
//                       <div className="product_zoom_thumb">
//                         <img
//                           src="assets/img/product/big-product/product1.png"
//                           alt=""
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-lg-6 col-md-6 col-sm-12">
//                     <div className="modal_right">
//                       <div className="modal_title mb-10">
//                         <h2>Donec Ac Tempus</h2>
//                       </div>
//                       <div className="modal_price mb-10">
//                         <span className="new_price">$64.99</span>
//                         <span className="old_price">$78.99</span>
//                       </div>
//                       <div className="modal_description mb-15">
//                         <p>
//                           Lorem ipsum dolor sit amet, consectetur adipisicing
//                           elit. Mollitia iste laborum ad impedit pariatur esse
//                           optio tempora sint ullam autem deleniti nam in quos
//                           qui,
//                         </p>
//                       </div>
//                       <div className="variants_selects">
//                         <div className="variants_size">
//                           <h2>size</h2>
//                           <select className="select_option">
//                             <option selected value="1">
//                               s
//                             </option>
//                             <option value="1">m</option>
//                             <option value="1">l</option>
//                             <option value="1">xl</option>
//                             <option value="1">xxl</option>
//                           </select>
//                         </div>
//                         <div className="variants_color">
//                           <h2>color</h2>
//                           <select className="select_option">
//                             <option selected value="1">
//                               purple
//                             </option>
//                             <option value="1">violet</option>
//                             <option value="1">black</option>
//                             <option value="1">pink</option>
//                             <option value="1">orange</option>
//                           </select>
//                         </div>
//                         <div className="modal_add_to_cart">
//                           <form action="#">
//                             <input
//                               min="1"
//                               max="100"
//                               step="2"
//                               value="1"
//                               type="number"
//                             />
//                             <button type="submit">add to cart</button>
//                           </form>
//                         </div>
//                       </div>
//                       <div className="modal_social">
//                         <h2>Share this product</h2>
//                         <ul>
//                           <li className="facebook">
//                             <a href="#">
//                               <i className="fa fa-facebook"></i>
//                             </a>
//                           </li>
//                           <li className="twitter">
//                             <a href="#">
//                               <i className="fa fa-twitter"></i>
//                             </a>
//                           </li>
//                           <li className="pinterest">
//                             <a href="#">
//                               <i className="fa fa-pinterest"></i>
//                             </a>
//                           </li>
//                           <li className="google-plus">
//                             <a href="#">
//                               <i className="fa fa-google-plus"></i>
//                             </a>
//                           </li>
//                           <li className="linkedin">
//                             <a href="#">
//                               <i className="fa fa-linkedin"></i>
//                             </a>
//                           </li>
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <Modal
//               isOpen={modalIsOpen}
//               onRequestClose={handleCloseModal}
//               className="modal"
//               overlayClassName="overlay"
//             >
//               <div className="modal-content">
//                 <img
//                   src={mainImage}
//                   alt="Full screen"
//                   style={{ width: "100%", height: "auto" }}
//                 />
//                 <button onClick={handlePreviousImage} className="modal-button">
//                   Previous
//                 </button>
//                 <button onClick={handleNextImage} className="modal-button">
//                   Next
//                 </button>
//                 <button onClick={handleCloseModal} className="modal-close">
//                   Close
//                 </button>
//               </div>
//             </Modal>
//           </div>
//           <style jsx>{`
//         .modal {
//           overlay: {
//             background-color: rgba(0, 0, 0, 0.75);
//           }
//           content: {
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             background: 'white',
//             border: 'none',
//             borderRadius: '10px',
//             padding: '20px',
//             width: '80%',
//             maxWidth: '800px',
//             maxHeight: '80%',
//             overflow: 'auto',
//           }
//         }
//         .modal-content {
//           position: relative;
//         }
//         .modal-button {
//           position: absolute;
//           top: 50%;
//           transform: translateY(-50%);
//           background: rgba(0, 0, 0, 0.5);
//           color: white;
//           border: none;
//           padding: 10px;
//           cursor: pointer;
//         }
//         .modal-button:first-of-type {
//           left: 10px;
//         }
//         .modal-button:last-of-type {
//           right: 10px;
//         }
//         .modal-close {
//           position: absolute;
//           top: 10px;
//           right: 10px;
//           background: rgba(0, 0, 0, 0.5);
//           color: white;
//           border: none;
//           padding: 10px;
//           cursor: pointer;
//         }
//       `}</style>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Productdetail;
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement("#root");

const Productdetail = () => {
  const thumbnails = [
    "assets/img/product/big-product/product1.png",
    "assets/img/product/small-product/product2.png",
    "assets/img/product/small-product/product3.png",
    "assets/img/product/small-product/product4.png",
  ];

  const [mainImage, setMainImage] = useState(thumbnails[0]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleThumbnailClick = (src, index) => {
    setMainImage(src);
    setCurrentIndex(index);
    // setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % thumbnails.length;
    setMainImage(thumbnails[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const handlePreviousImage = () => {
    const prevIndex =
      (currentIndex - 1 + thumbnails.length) % thumbnails.length;
    setMainImage(thumbnails[prevIndex]);
    setCurrentIndex(prevIndex);
  };
  const productdetailimagefullview = () => {
    setModalIsOpen(true);
  };

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    speed: 300,
    infinite: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 300, settings: { slidesToShow: 1 } },
    ],
  };

  const products = [
    {
      id: 1,
      name: "Basic Joggin Shorts",
      image: "assets/img/product/product1.jpg",
      secondaryImage: "assets/img/product/product2.jpg",
      price: "₹300",
      oldPrice: "₹500",
      rating: 4,
      reviews: 4,
    },
    {
      id: 2,
      name: "Make Thing Happen T-Shirts",
      image: "assets/img/product/product2.jpg",
      secondaryImage: "assets/img/product/product2.jpg",
      price: "₹300",
      rating: 5,
      reviews: 6,
      discount: "-20%",
    },
    {
      id: 3,
      name: "Basic White Simple Sneaker",
      image: "assets/img/product/product3.jpg",
      secondaryImage: "assets/img/product/product2.jpg",
      price: "₹300",
      oldPrice: "₹500",
      rating: 4,
      reviews: 2,
      discount: "-18%",
    },
    {
      id: 4,
      name: "Basic White Simple Sneaker",
      image: "assets/img/product/product2.jpg",
      secondaryImage: "assets/img/product/product2.jpg",
      price: "₹300",
      oldPrice: "₹500",
      rating: 4,
      reviews: 2,
      discount: "-18%",
    },
  ];

  return (
    <div>
      <nav aria-label="breadcrumb" id="headerspace">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Product detail
          </li>
        </ol>
      </nav>

      <section className="product_details mb-135">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="product_zoom_gallery">
                <div className="zoom_gallery_inner d-flex">
                  <div className="zoom_tab_img">
                    {thumbnails.map((thumbnail, index) => (
                      <a
                        key={index}
                        className="zoom_tabimg_list"
                        href="#"
                        onClick={() => handleThumbnailClick(thumbnail, index)}
                      >
                        <img src={thumbnail} alt="tab-thumb" />
                      </a>
                    ))}
                  </div>

                  <div className="product_zoom_main_img">
                    <div className="product_zoom_thumb">
                      <img
                        src={mainImage}
                        alt="Main product"
                        style={{ maxWidth: "100%", height: "600px" }}
                        onClick={() =>
                          handleThumbnailClick(
                            mainImage,
                            thumbnails.indexOf(mainImage)
                          )
                        }
                      />
                    </div>
                    <div className="productdetailimagefullview-icon">
                      <a onClick={productdetailimagefullview} href="#">
                        <i className="icon-size-fullscreen icons"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="product_d_right">
                <form action="#">
                  <h1>Edwin T-Shirt Logo Script Print in Navy</h1>
                  <div className="product_ratting_review d-flex align-items-center">
                    <div className="product_ratting">
                      <ul className="d-flex">
                        {[...Array(5)].map((_, i) => (
                          <li key={i}>
                            <a href="#">
                              <i className="ion-ios-star"></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="product_review">
                      <ul className="d-flex">
                        <li>4 reviews</li>
                        <li>Write your review</li>
                      </ul>
                    </div>
                  </div>
                  <div className="price_box">
                    <span className="current_price">₹300</span>
                  </div>
                  <div className="product_availalbe">
                    <ul className="d-flex">
                      <li>
                        <i className="icon-layers icons"></i> Only{" "}
                        <span>15</span> left{" "}
                      </li>
                      <li>
                        Availalbe: <span className="stock">In Stock</span>
                      </li>
                    </ul>
                  </div>
                  <div className="product_desc">
                    <p>
                      A t-shirt that comes in three colors (red, white, and
                      blue) and three sizes (small, medium, large) is a
                      configurable product.
                    </p>
                  </div>
                  <div className="product_variant">
                    <div className="filter__list widget_color d-flex align-items-center">
                      <h3>Select color</h3>
                      <ul>
                        <li>
                          <input type="checkbox" />
                          <span className="checkmark color1"></span>
                        </li>
                        <li>
                          <input type="checkbox" />
                          <span className="checkmark color2"></span>
                        </li>
                        <li>
                          <input type="checkbox" />
                          <span className="checkmark color3"></span>
                        </li>
                        <li>
                          <input type="checkbox" />
                          <span className="checkmark color5"></span>
                        </li>
                      </ul>
                    </div>
                    <div className="filter__list widget_size d-flex align-items-center">
                      <h3>Select size</h3>
                      <ul>
                        <li>
                          <a href="#">S</a>
                        </li>
                        <li>
                          <a href="#">M</a>
                        </li>
                        <li>
                          <a href="#">L</a>
                        </li>
                        <li>
                          <a href="#">XL</a>
                        </li>
                        <li>
                          <a href="#">XXL</a>
                        </li>
                      </ul>
                    </div>

                    <div class="variant_quantity_btn d-flex">
                      <div class="pro-qty border">
                        <input min="1" max="100" type="tex" value="1" />
                      </div>
                      <button class="button btn btn-primary" type="submit">
                        <i class="ion-android-add"></i> Add To Cart
                      </button>
                      <a class="wishlist" href="#">
                        <i class="ion-ios-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div className="product_sku">
                    <p>
                      <span>SKU: </span> #ABC123456
                    </p>
                  </div>
                  <div className="product_tags d-flex">
                    <span>Tags: </span>
                    <ul className="d-flex">
                      <li>
                        <a href="#">fashion,</a>
                      </li>
                      <li>
                        <a href="#">clothing,</a>
                      </li>
                      <li>
                        <a href="#">shirt</a>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Image Modal"
        className="productdetailimg-Modal"
        overlayClassName="productdetailimg-Overlay"
      >
        <button
          className="productdetailimg-close-button"
          onClick={handleCloseModal}
        >
          &times;
        </button>
        <div className="productdetailimg-modal-content">
          <button
            className="productdetailimg-modal-prev"
            onClick={handlePreviousImage}
          >
            &lt;
          </button>
          <img src={mainImage} alt="Modal" />
          <button
            className="productdetailimg-modal-next"
            onClick={handleNextImage}
          >
            &gt;
          </button>
        </div>
      </Modal>

      <section className="related_product pt-60 pb-60">
        <div className="container">
          <div className="section_title text-center mb-50">
            <h2>Related Products</h2>
          </div>
          <Slider {...settings}>
            {products.map((product) => (
              <div className="col-lg-3" key={product.id}>
                <article className="single_product">
                  <figure>
                    <div className="product_thumb">
                      <Link to="/Productdetail">
                        <img
                          className="primary_img"
                          src={product.image}
                          alt={product.name}
                        />
                        <img
                          className="secondary_img"
                          src={product.secondaryImage}
                          alt={product.name + " second view"}
                        />
                      </Link>
                      <div className="product_action">
                        <ul>
                          <li className="wishlist">
                            <Link to="/Favourite" title="Wishlist">
                              <i className="icon-heart icons"></i>
                            </Link>
                          </li>
                          <li className="quick_view">
                            <a
                              data-toggle="modal"
                              data-target="#modal_box"
                              title="Quick View"
                              href="#"
                            >
                              <i className="icon-size-fullscreen icons"></i>
                            </a>
                          </li>
                          <li className="compare">
                            <Link to="/product" title="Add Cart">
                              <i className="icon-basket-loaded icons"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      {product.discount && (
                        <div className="product_label">
                          <span>{product.discount}</span>
                        </div>
                      )}
                    </div>
                    <figcaption className="product_content text-center">
                      <div className="product_ratting">
                        <ul className="d-flex justify-content-center">
                          {[...Array(product.rating)].map((_, i) => (
                            <li key={i}>
                              <a href="#">
                                <i className="ion-android-star"></i>
                              </a>
                            </li>
                          ))}
                          <li>
                            <span>({product.reviews})</span>
                          </li>
                        </ul>
                      </div>
                      <h4 className="product_name">
                        <a href="product-details.html">{product.name}</a>
                      </h4>
                      <div className="price_box">
                        <span className="current_price">{product.price}</span>
                        {product.oldPrice && (
                          <span className="old_price">{product.oldPrice}</span>
                        )}
                      </div>
                      <div className="add_to_cart">
                        <Link
                          className="btn btn-primary"
                          to="/Cart"
                          title="Add To Cart"
                        >
                          Add To Cart
                        </Link>
                      </div>
                    </figcaption>
                  </figure>
                </article>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <div
        className="modal fade"
        id="modal_box"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="ion-android-close"></i>
              </span>
            </button>
            <div className="modal_body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="modal_zoom_gallery">
                      <div className="big_image_container">
                        <img
                          id="bigImage"
                          src="assets/img/product/big-product/product1.png"
                          alt="Big Product"
                          className="big_image"
                        />
                      </div>
                      <div className="small_images_container">
                        <img
                          src="assets/img/product/big-product/product1.png"
                          alt="Product 1"
                          className="small_image"
                          onClick={() =>
                            (document.getElementById("bigImage").src =
                              "assets/img/product/big-product/product1.png")
                          }
                        />
                        <img
                          src="assets/img/product/product1.jpg"
                          alt="Product 2"
                          className="small_image"
                          onClick={() =>
                            (document.getElementById("bigImage").src =
                              "assets/img/product/product1.jpg")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="modal_right">
                      <div className="modal_title mb-10">
                        <h2>Donec Ac Tempus</h2>
                      </div>
                      <div className="modal_price mb-10">
                        <span className="new_price">$64.99</span>
                        <span className="old_price">$78.99</span>
                      </div>
                      <div className="modal_description mb-15">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Mollitia iste laborum ad impedit pariatur esse
                          optio tempora sint ullam autem deleniti nam in quos
                          qui,
                        </p>
                      </div>
                      <div className="variants_selects">
                        <div className="variants_size">
                          <h2>Size</h2>
                          <select className="select_option">
                            <option selected value="1">
                              S
                            </option>
                            <option value="1">M</option>
                            <option value="1">L</option>
                            <option value="1">XL</option>
                            <option value="1">XXL</option>
                          </select>
                        </div>
                        <div className="variants_color">
                          <h2>Color</h2>
                          <select className="select_option">
                            <option selected value="1">
                              Purple
                            </option>
                            <option value="1">Violet</option>
                            <option value="1">Black</option>
                            <option value="1">Pink</option>
                            <option value="1">Orange</option>
                          </select>
                        </div>
                        <div className="modal_add_to_cart">
                          <form action="#">
                            <input
                              min="1"
                              max="100"
                              step="2"
                              value="1"
                              type="number"
                            />
                            <button type="submit">Add to Cart</button>
                          </form>
                        </div>
                      </div>
                      <div className="modal_social">
                        <h2>Share this product</h2>
                        <ul>
                          <li className="facebook">
                            <a href="#">
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li className="twitter">
                            <a href="#">
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li className="pinterest">
                            <a href="#">
                              <i className="fa fa-pinterest"></i>
                            </a>
                          </li>
                          <li className="google-plus">
                            <a href="#">
                              <i className="fa fa-google-plus"></i>
                            </a>
                          </li>
                          <li className="linkedin">
                            <a href="#">
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productdetail;
