import React, { useState, useEffect } from "react";
import Minicart from "../Pages/Minicart";
import { Link } from "react-router-dom";
import { fetchcategorylist } from "../Pages/Apiservice";

function Header() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for Product dropdown
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const data = await fetchcategorylist();
        setCategories(data);
        setLoading(false);
      } catch (error) {
        setError("Failed to load categories.");
        setLoading(false);
      }
    };
    getCategories();
  }, []);

  const toggleCart = () => {
    setIsCartOpen((prevState) => !prevState); // Toggle the visibility state
  };

  const closeCart = () => {
    setIsCartOpen(false);
  };

  const [isScrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <header className={`header_section ${isScrolled ? "header_scrolled" : ""}`}>
      <div className="header_overlay">
        <div className="header_content">
          <div className="header_top">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="header_top_inner d-flex justify-content-between align-items-center">
                    <div className="header_contact_info">
                      <ul className="d-flex">
                        <li className="text-white">
                          <i className="icons icon-phone"></i>
                          <Link to="tel:+05483716566">0123456789 </Link>
                        </li>
                        <li className="text-white">
                          <i className="icon-envelope-letter icons"></i>
                          <Link to="mailto:uthrstore@domain.com">
                            infiboutique@gmail.com
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="header_top_sidebar d-flex align-items-center">
                      <div className="header_social d-flex">
                        <span>Follow us</span>
                        <ul className="d-flex">
                          <li>
                            <Link to="#">
                              <i className="icon-social-twitter icons"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-social-facebook icons"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-social-instagram icons"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-social-youtube icons"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-social-pinterest icons"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main_header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="header_container d-flex justify-content-between align-items-center">
                    <div className="canvas_open" onClick={toggleNav}>
                      <Link to="javascript:void(0)">
                        <i className="ion-navicon"></i>
                      </Link>
                    </div>
                    <div className="header_logo">
                      <Link className="sticky_none" to="/">
                        <h3
                          style={{
                            fontFamily: '"Caveat", cursive',
                            fontOpticalSizing: "auto",
                            paddingLeft: "30px",
                          }}
                        >
                          Infiboutique
                        </h3>
                        {/* <img
                          src="./assets/img/logo/logo.jpg"
                          alt=""
                          style={{ height: "50px" }}
                        /> */}
                      </Link>
                    </div>

                    <div className="main_menu d-none d-lg-block">
                      <nav>
                        <ul className="d-flex align-items-center justify-content-center">
                          <li>
                            <Link className="active" to="/">
                              Home
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="/product" className="nav-link ">
                              Product
                            </Link>

                            <ul className="dropdown_Product">
                              {categories.map((category) => (
                                <li
                                  key={category.id}
                                  style={{ width: "150px" }}
                                >
                                  <Link to="#">{category.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </li>

                          <li>
                            <Link to="/Cart">Cart </Link>
                          </li>
                          <li>
                            <Link to="/Payment">Payment </Link>
                          </li>
                          <li>
                            <Link to="/Contact">Contact </Link>
                          </li>
                          <li>
                            <Link to="/AuthForm">signup/login </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="header_account">
                      <ul className="d-flex">
                        <li className="header_search">
                          <Link to="#">
                            <i className="icon-magnifier icons"></i>
                          </Link>
                        </li>

                        <li>
                          <Link to="/Favourite">
                            <i className="icon-heart icons"></i>
                          </Link>
                          <span className="item_count">2</span>
                        </li>
                        <li className="shopping_cart" onClick={toggleCart}>
                          <Link to="#">
                            <i className="icon-basket-loaded icons"></i>
                          </Link>
                          <span className="item_count">2</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`mobile_nav ${isNavOpen ? "open" : ""}`}>
        <i
          className="ion-android-close"
          onClick={closeNav}
          style={{ color: "black", fontSize: "30px", marginLeft: "30px" }}
        ></i>
        <ul>
          <li>
            <Link to="/">Home </Link>
          </li>
          <li className="productheaderalign-mobile">
            <Link to="#" onClick={toggleDropdown}>
              Product{" "}
              <i className="arrow-down" style={{ marginLeft: "5px" }}></i>
            </Link>
            {isDropdownOpen && (
              <ul className="dropdown_Product">
                {categories.map((category) => (
                  <li key={category.id}>
                    <Link to="#"> {category.name} </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>

          <li>
            <Link to="/Cart">Cart </Link>
          </li>
          <li>
            <Link to="/Payment">Payment </Link>
          </li>
          <li>
            <Link to="/Contact">Contact </Link>
          </li>
          <li>
            <Link to="/AuthForm">signup/login </Link>
          </li>
        </ul>
      </div>
      <Minicart isOpen={isCartOpen} onClose={closeCart} />
    </header>
  );
}

export default Header;
