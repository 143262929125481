import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { fetchcategorylist } from "./Apiservice";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import Notification from "./Notification";
import Minicart from "./Minicart";
import { Link } from "react-router-dom";
import { FaTruckMoving } from "react-icons/fa";

const Home = () => {
  const [categories, setCategories] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch categories when the component mounts
    const getCategories = async () => {
      try {
        const data = await fetchcategorylist();
        setCategories(data);
        // setLoading(false);
      } catch (error) {
        // setError("Failed to load categories.");
        // setLoading(false);
      }
    };
    getCategories();
  }, []);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,

    speed: 500,
    infinite: true,
  };
  const settingscard = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    speed: 300,
    infinite: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 300, settings: { slidesToShow: 1 } },
    ],
  };

  // Custom Next Arrow
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="slick-arrow slick-next" onClick={onClick}>
        <i className="icon-arrow-right"></i>
      </button>
    );
  };

  // Custom Prev Arrow
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="slick-arrow slick-prev" onClick={onClick}>
        <i className="icon-arrow-left"></i>
      </button>
    );
  };

  const products = [
    {
      id: 1,
      name: "Basic Joggin Shorts",
      image: "assets/img/product/product1.jpg",
      secondaryImage: "assets/img/product/product2.jpg",
      price: " ₹300",
      oldPrice: " ₹500",
      rating: 4,
      reviews: 4,
    },
    {
      id: 2,
      name: "Make Thing Happen T-Shirts",
      image: "assets/img/product/product2.jpg",
      secondaryImage: "assets/img/product/product3.jpg",
      price: " ₹300",
      rating: 5,
      reviews: 6,
      discount: "-20%",
    },
    {
      id: 3,
      name: "Basic White Simple Sneaker",
      image: "assets/img/product/product3.jpg",
      secondaryImage: "assets/img/product/product4.jpg",
      price: " ₹300",
      oldPrice: " ₹500",
      rating: 4,
      reviews: 2,
      discount: "-18%",
    },
    {
      id: 4,
      name: "Basic White Simple Sneaker",
      image: "assets/img/product/product2.jpg",
      secondaryImage: "assets/img/product/product1.jpg",
      price: " ₹300",
      oldPrice: " ₹500",
      rating: 4,
      reviews: 2,
      discount: "-18%",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "Are these only Maternity wear Outfits?",
      answer:
        "No, all are normal outfits. Maternity zips are just an option to select as per customer choice.",
    },
    {
      question: "How to place an International Order?",
      answer:
        "International orders are taken through direct messages (DM). Send us your cart screenshot on Instagram to place an international order.",
    },
    {
      question: "Can you customize the outfits?",
      answer:
        "Only the length of the outfit can be customized as per the size chart. We do not take other customizations.",
    },
  ];

  return (
    <div>
      <section className="slider_section mb-63">
        <Slider {...settings} className="slider_area">
          <div
            style={{
              backgroundImage:
                'url("https://ruffleberry.com/wp-content/uploads/2024/07/1.png")',
            }}
          >
            <div
              className="single_slider d-flex align-items-center"
              style={{
                backgroundImage:
                  'url("https://ruffleberry.com/wp-content/uploads/2024/07/2.png")',
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-7">
                    <div className="slider_text" style={{ color: "white" }}>
                      <span>Lookbook</span>
                      <h1>Fashion trend for autumn girls with vibes</h1>
                      <p>
                        We love seeing how our Aslam wearers like <br /> to wear
                        their Norda
                      </p>
                      <Link className="btn btn-primary" to="/product">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage:
                'url("https://ruffleberry.com/wp-content/uploads/2024/07/1.png")',
            }}
          >
            <div
              className="single_slider d-flex align-items-center"
              style={{
                backgroundImage:
                  'url("https://ruffleberry.com/wp-content/uploads/2024/07/2.png")',
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-7">
                    <div className="slider_text" style={{ color: "white" }}>
                      <span>Lookbook</span>
                      <h1>Fashion trend for autumn girls with vibes</h1>
                      <p>
                        We love seeing how our Aslam wearers like <br /> to wear
                        their Norda
                      </p>
                      <Link className="btn btn-primary" to="/product">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: 'url("./assets/img/slider/slider1.jpg")',
            }}
          >
            <div
              className="single_slider d-flex align-items-center"
              style={{
                backgroundImage:
                  'url("https://ruffleberry.com/wp-content/uploads/2024/07/2.png")',
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-7">
                    <div className="slider_text" style={{ color: "white" }}>
                      <span>Lookbook</span>
                      <h1>Fashion trend for autumn girls with vibes</h1>
                      <p>
                        We love seeing how our Aslam wearers like <br /> to wear
                        their Norda
                      </p>
                      <Link className="btn btn-primary" to="/product">
                        Explore Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>
      <section class="shipping_section mb-102">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="shipping_inner d-flex justify-content-between">
                <div class="single_shipping d-flex align-items-center">
                  <div class="shipping_icon">
                    <i class="icon-cursor icons"></i>
                  </div>
                  <div class="shipping_text">
                    <h3>Free Shipping</h3>
                    <p>Free Shipping all over India</p>
                  </div>
                </div>
                <div class="single_shipping d-flex align-items-center">
                  <div class="shipping_icon">
                    <i class="icon-reload icons"></i>
                  </div>
                  <div class="shipping_text">
                    <h3>Dispatch Period</h3>
                    <p>order will be dispatched in 15-20 business days</p>
                  </div>
                </div>
                <div class="single_shipping d-flex align-items-center">
                  <div class="shipping_icon">
                    <i class="icon-lock icons"></i>
                  </div>
                  <div class="shipping_text">
                    <h3>Made To Order</h3>
                    <p>Outfits will be made after placing the order</p>
                  </div>
                </div>
                <div class="single_shipping d-flex align-items-center">
                  <div class="shipping_icon">
                    <i class="icon-tag icons"></i>
                  </div>
                  <div class="shipping_text">
                    <h3>Made To Height</h3>
                    <p>Length of the outfit as per customer Height</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>Shop By Category</h2>
              </div>
            </div>
          </div>

          <div className="product_container row">
            {categories.map((category) => (
              <div className="col-lg-3" key={category.id}>
                <article className="single_product" id="Categorycard">
                  <figure>
                    <div className="product_thumb">
                      <Link to={`/category/${category.id}`}>
                        <img
                          className="primary_img"
                          src={category.image || "default-image.jpg"} // Default image fallback
                          alt={category.name}
                        />
                      </Link>
                    </div>
                    <figcaption className="product_content text-center">
                      <h4 className="product_name">
                        <Link to={`/category/${category.id}`}>
                          {category.name}
                        </Link>
                      </h4>
                    </figcaption>
                  </figure>
                </article>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>Our Latest Collection</h2>
              </div>
            </div>
          </div>

          <div className="product_container row">
            <Slider {...settingscard}>
              {products.map((product) => (
                <div className="col-lg-3" key={product.id}>
                  <article className="single_product">
                    <figure>
                      <div className="product_thumb">
                        <Link to="/Productdetail">
                          <img
                            className="primary_img"
                            src={product.image}
                            alt={product.name}
                          />
                          <img
                            className="secondary_img"
                            src={product.secondaryImage}
                            alt={product.name + " second view"}
                          />
                        </Link>
                        <div className="product_action">
                          <ul>
                            <li className="wishlist">
                              <Link to="/Favourite" title="Wishlist">
                                <i className="icon-heart icons"></i>
                              </Link>
                            </li>
                            <li className="quick_view">
                              <a
                                data-toggle="modal"
                                data-target="#modal_box"
                                title="Quick View"
                                href="#"
                              >
                                <i className="icon-size-fullscreen icons"></i>
                              </a>
                            </li>
                            <li className="compare">
                              <Link to="/product" title="Add Cart">
                                <i className="icon-basket-loaded icons"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        {product.discount && (
                          <div className="product_label">
                            <span>{product.discount}</span>
                          </div>
                        )}
                      </div>
                      <figcaption className="product_content text-center">
                        <div className="product_ratting">
                          <ul className="d-flex justify-content-center">
                            {[...Array(product.rating)].map((_, i) => (
                              <li key={i}>
                                <a href="#">
                                  <i className="ion-android-star"></i>
                                </a>
                              </li>
                            ))}
                            <li>
                              <span>({product.reviews})</span>
                            </li>
                          </ul>
                        </div>
                        <h4 className="product_name">
                          <a href="product-details.html">{product.name}</a>
                        </h4>
                        <div className="price_box">
                          <span className="current_price">{product.price}</span>
                          {product.oldPrice && (
                            <span className="old_price">
                              {product.oldPrice}
                            </span>
                          )}
                        </div>
                        <div className="add_to_cart">
                          <Link
                            className="btn btn-primary"
                            to="/Cart"
                            title="Add To Cart"
                          >
                            Add To Cart
                          </Link>
                        </div>
                      </figcaption>
                    </figure>
                  </article>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <section className="accordion">
        <div className="accordion-left">
          <h1>FAQ</h1>
          <p>
            Check out the most common questions our customers ask. If you have
            more questions, please contact our customer support.
          </p>
        </div>
        <div className="accordion-right">
          {faqItems.map((item, index) => (
            <div key={index} className="accordion-item">
              <button
                className="accordion-header"
                onClick={() => toggleAccordion(index)}
              >
                <h3>{item.question}</h3>
              </button>
              <div
                className={`accordion-content ${
                  openIndex === index ? "open" : ""
                }`}
              >
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>Featured Products</h2>
              </div>
            </div>
          </div>
          <div className="product_container row">
            <Slider {...settingscard}>
              {products.map((product) => (
                <div className="col-lg-3" key={product.id}>
                  <article className="single_product">
                    <figure>
                      <div className="product_thumb">
                        <Link to="/Productdetail">
                          <img
                            className="primary_img"
                            src={product.image}
                            alt={product.name}
                          />
                        </Link>
                        <div className="product_action">
                          <ul>
                            <li className="wishlist">
                              <Link to="/Favourite" title="Wishlist">
                                <i className="icon-heart icons"></i>
                              </Link>
                            </li>
                            <li className="quick_view">
                              <a
                                data-toggle="modal"
                                data-target="#modal_box"
                                title="Quick View"
                                href="#"
                              >
                                <i className="icon-size-fullscreen icons"></i>
                              </a>
                            </li>
                            <li className="compare">
                              <Link to="/product" title="Add Cart">
                                <i className="icon-basket-loaded icons"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        {product.discount && (
                          <div className="product_label">
                            <span>{product.discount}</span>
                          </div>
                        )}
                      </div>
                      <figcaption className="product_content text-center">
                        <div className="product_ratting">
                          <ul className="d-flex justify-content-center">
                            {[...Array(product.rating)].map((_, i) => (
                              <li key={i}>
                                <a href="#">
                                  <i className="ion-android-star"></i>
                                </a>
                              </li>
                            ))}
                            <li>
                              <span>({product.reviews})</span>
                            </li>
                          </ul>
                        </div>
                        <h4 className="product_name">
                          <a href="product-details.html">{product.name}</a>
                        </h4>
                        <div className="price_box">
                          <span className="current_price">{product.price}</span>
                          {product.oldPrice && (
                            <span className="old_price">
                              {product.oldPrice}
                            </span>
                          )}
                        </div>
                        <div className="add_to_cart">
                          <Link
                            className="btn btn-primary"
                            to="/Cart"
                            title="Add To Cart"
                          >
                            Add To Cart
                          </Link>
                        </div>
                      </figcaption>
                    </figure>
                  </article>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      {/* <section className="testmonial">
        <div className="testmonial-text">
          <h1>Client Testimonials</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore,
            modi?
          </p>
          <h5>barathidhasan</h5>
        </div>
      </section> */}

      <section className="testmonial">
        <div className="testmonial-text">
          <h1>Client Testimonials</h1>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation={false}
            autoplay={{ delay: 3000 }}
            loop={true}
            modules={[Pagination, Navigation, Autoplay]}
          >
            <SwiperSlide>
              <div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Inventore, modi?
                </p>
                <h5>Barathidhasan</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque.
                </p>
                <h5>John Doe</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis praesentium.
                </p>
                <h5>Jane Smith</h5>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>Best Seller Products</h2>
              </div>
            </div>
          </div>
          <div className="product_container row">
            <Slider {...settingscard}>
              {products.map((product) => (
                <div className="col-lg-3" key={product.id}>
                  <article className="single_product">
                    <figure>
                      <div className="product_thumb">
                        <Link to="/Productdetail">
                          <img
                            className="primary_img"
                            src={product.image}
                            alt={product.name}
                          />
                        </Link>
                        <div className="product_action">
                          <ul>
                            <li className="wishlist">
                              <Link to="/Favourite" title="Wishlist">
                                <i className="icon-heart icons"></i>
                              </Link>
                            </li>
                            <li className="quick_view">
                              <a
                                data-toggle="modal"
                                data-target="#modal_box"
                                title="Quick View"
                                href="#"
                              >
                                <i className="icon-size-fullscreen icons"></i>
                              </a>
                            </li>
                            <li className="compare">
                              <Link to="/product" title="Add Cart">
                                <i className="icon-basket-loaded icons"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        {product.discount && (
                          <div className="product_label">
                            <span>{product.discount}</span>
                          </div>
                        )}
                      </div>
                      <figcaption className="product_content text-center">
                        <div className="product_ratting">
                          <ul className="d-flex justify-content-center">
                            {[...Array(product.rating)].map((_, i) => (
                              <li key={i}>
                                <a href="#">
                                  <i className="ion-android-star"></i>
                                </a>
                              </li>
                            ))}
                            <li>
                              <span>({product.reviews})</span>
                            </li>
                          </ul>
                        </div>
                        <h4 className="product_name">
                          <a href="product-details.html">{product.name}</a>
                        </h4>
                        <div className="price_box">
                          <span className="current_price">{product.price}</span>
                          {product.oldPrice && (
                            <span className="old_price">
                              {product.oldPrice}
                            </span>
                          )}
                        </div>
                        <div className="add_to_cart">
                          <Link
                            className="btn btn-primary"
                            to="/Cart"
                            title="Add To Cart"
                          >
                            Add To Cart
                          </Link>
                        </div>
                      </figcaption>
                    </figure>
                  </article>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      {/* <div
        className="modal fade"
        id="modal_box"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="ion-android-close"></i>
              </span>
            </button>
            <div className="modal_body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="modal_zoom_gallery">
                      <div className="product_zoom_thumb">
                        <img
                          src="assets/img/product/big-product/product1.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="modal_right">
                      <div className="modal_title mb-10">
                        <h2>Donec Ac Tempus</h2>
                      </div>
                      <div className="modal_price mb-10">
                        <span className="new_price">$64.99</span>
                        <span className="old_price">$78.99</span>
                      </div>
                      <div className="modal_description mb-15">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Mollitia iste laborum ad impedit pariatur esse
                          optio tempora sint ullam autem deleniti nam in quos
                          qui,
                        </p>
                      </div>
                      <div className="variants_selects">
                        <div className="variants_size">
                          <h2>size</h2>
                          <select className="select_option">
                            <option selected value="1">
                              s
                            </option>
                            <option value="1">m</option>
                            <option value="1">l</option>
                            <option value="1">xl</option>
                            <option value="1">xxl</option>
                          </select>
                        </div>
                        <div className="variants_color">
                          <h2>color</h2>
                          <select className="select_option">
                            <option selected value="1">
                              purple
                            </option>
                            <option value="1">violet</option>
                            <option value="1">black</option>
                            <option value="1">pink</option>
                            <option value="1">orange</option>
                          </select>
                        </div>
                        <div className="modal_add_to_cart">
                          <form action="#">
                            <input
                              min="1"
                              max="100"
                              step="2"
                              value="1"
                              type="number"
                            />
                            <button type="submit">add to cart</button>
                          </form>
                        </div>
                      </div>
                      <div className="modal_social">
                        <h2>Share this product</h2>
                        <ul>
                          <li className="facebook">
                            <a href="#">
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li className="twitter">
                            <a href="#">
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li className="pinterest">
                            <a href="#">
                              <i className="fa fa-pinterest"></i>
                            </a>
                          </li>
                          <li className="google-plus">
                            <a href="#">
                              <i className="fa fa-google-plus"></i>
                            </a>
                          </li>
                          <li className="linkedin">
                            <a href="#">
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="modal fade"
        id="modal_box"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="ion-android-close"></i>
              </span>
            </button>
            <div className="modal_body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="modal_zoom_gallery">
                      <div className="big_image_container">
                        <img
                          id="bigImage"
                          src="assets/img/product/big-product/product1.png"
                          alt="Big Product"
                          className="big_image"
                        />
                      </div>
                      <div className="small_images_container">
                        <img
                          src="assets/img/product/big-product/product1.png"
                          alt="Product 1"
                          className="small_image"
                          onClick={() =>
                            (document.getElementById("bigImage").src =
                              "assets/img/product/big-product/product1.png")
                          }
                        />
                        <img
                          src="assets/img/product/product1.jpg"
                          alt="Product 2"
                          className="small_image"
                          onClick={() =>
                            (document.getElementById("bigImage").src =
                              "assets/img/product/product1.jpg")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="modal_right">
                      <div className="modal_title mb-10">
                        <h2>Donec Ac Tempus</h2>
                      </div>
                      <div className="modal_price mb-10">
                        <span className="new_price">$64.99</span>
                        <span className="old_price">$78.99</span>
                      </div>
                      <div className="modal_description mb-15">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Mollitia iste laborum ad impedit pariatur esse
                          optio tempora sint ullam autem deleniti nam in quos
                          qui,
                        </p>
                      </div>
                      <div className="variants_selects">
                        <div className="variants_size">
                          <h2>Size</h2>
                          <select className="select_option">
                            <option selected value="1">
                              S
                            </option>
                            <option value="1">M</option>
                            <option value="1">L</option>
                            <option value="1">XL</option>
                            <option value="1">XXL</option>
                          </select>
                        </div>
                        <div className="variants_color">
                          <h2>Color</h2>
                          <select className="select_option">
                            <option selected value="1">
                              Purple
                            </option>
                            <option value="1">Violet</option>
                            <option value="1">Black</option>
                            <option value="1">Pink</option>
                            <option value="1">Orange</option>
                          </select>
                        </div>
                        <div className="modal_add_to_cart">
                          <form action="#">
                            <input
                              min="1"
                              max="100"
                              step="2"
                              value="1"
                              type="number"
                            />
                            <button type="submit">Add to Cart</button>
                          </form>
                        </div>
                      </div>
                      <div className="modal_social">
                        <h2>Share this product</h2>
                        <ul>
                          <li className="facebook">
                            <a href="#">
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li className="twitter">
                            <a href="#">
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li className="pinterest">
                            <a href="#">
                              <i className="fa fa-pinterest"></i>
                            </a>
                          </li>
                          <li className="google-plus">
                            <a href="#">
                              <i className="fa fa-google-plus"></i>
                            </a>
                          </li>
                          <li className="linkedin">
                            <a href="#">
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="banner_section mb-109">
        <div class="container">
          <div class="section_title mb-60">
            <h2>featured collections</h2>
          </div>
          <div class="banner_container d-flex">
            <figure class="single_banner position-relative mr-30">
              <img src="assets/img/bg/bg1.jpg" alt="" />
              <figcaption class="banner_text position-absolute">
                <h3>
                  Zara Pattern <br></br>backpacks
                </h3>
                <p>
                  Stretch, fresh-cool help you alway <br></br> comfortable
                </p>
                <Link class="btn btn-primary" to="/product">
                  Shop Now
                </Link>
              </figcaption>
            </figure>
            <figure class="single_banner position-relative">
              <img src="assets/img/bg/bg2.jpg" alt="" />
              <figcaption class="banner_text position-absolute">
                <h3 class="text-white">Basic Color Caps</h3>
                <p class="text-white">
                  Minimalist never cool, choose and make <br></br> the simple
                  great again!
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <Notification />
    </div>
  );
};

export default Home;
