import axios from "axios";

const BASE_URL = "https://infifashion.bwsoft.in/api/api/";

const ApiService = axios.create({
  baseURL: BASE_URL,
});

export const fetchcategorylist = async () => {
  try {
    const response = await ApiService.get("categories/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};

// Register user
export const signupUser = async (userData) => {
  try {
    const response = await ApiService.post("register/", userData);
    return response.data;
  } catch (error) {
    console.error("Error during registration", error);
    throw error;
  }
};

// OtpUser API service function
export const OtpUser = async (userData) => {
  try {
    const response = await ApiService.post("verify-otp/", userData);
    return response.data;
  } catch (error) {
    console.error("Error during OTP verification", error);
    throw error;
  }
};
// Login user
export const loginUser = async (userData) => {
  try {
    const response = await ApiService.post("login/", userData);
    return response.data;
  } catch (error) {
    console.error("Error during login", error);
    throw error;
  }
};
// Password reset request (send reset link)
export const requestPasswordReset = async (email) => {
  try {
    const response = await ApiService.post("password-reset/", { email });
    return response.data;
  } catch (error) {
    console.error("Error during password reset request", error);
    throw error;
  }
};

// Password reset confirmation (set new password)
export const confirmPasswordReset = async (userData) => {
  try {
    const response = await ApiService.post("password-reset-confirm/", userData);
    return response.data;
  } catch (error) {
    console.error(
      "Error during password reset confirmation",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const submitContactForm = async (formData) => {
  try {
    const response = await ApiService.post("contact/", formData);
    return response.data;
  } catch (error) {
    console.error("Error during contact form submission", error);
    throw error;
  }
};
