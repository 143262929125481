import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const Product = () => {
  const products = [
    {
      imgSrc: "assets/img/product/product1.jpg",
      name: "Basic Joggin Shorts",
      currentPrice: "₹300",
      oldPrice: "₹500",
      rating: 4,
      discount: null,
      action: "Add To Cart",
    },
    {
      imgSrc: "assets/img/product/product2.jpg",
      name: "Make Thing Happen T-Shirts",
      currentPrice: "₹300",
      oldPrice: null,
      rating: 6,
      discount: "-20%",
      action: "Add To Cart",
    },
    {
      imgSrc: "assets/img/product/product3.jpg",
      name: "Basic White Simple Sneaker",
      currentPrice: "₹300",
      oldPrice: "₹500",
      rating: 2,
      discount: "-18%",
      action: "Add To Cart",
    },
    {
      imgSrc: "assets/img/product/product4.jpg",
      name: "Simple Rounded Sunglasses",
      currentPrice: "₹300",

      oldPrice: null,
      rating: 8,
      discount: null,
      action: "Add To Cart",
    },
    {
      imgSrc: "assets/img/product/product1.jpg",
      name: "Basic Joggin Shorts",
      currentPrice: "₹300",
      oldPrice: "₹500",
      rating: 12,
      discount: null,
      action: "Add To Cart",
    },
    {
      imgSrc: "assets/img/product/product2.jpg",
      name: "Simple Rounded Sunglasses",
      currentPrice: "₹300",
      oldPrice: "₹500",
      rating: 14,
      discount: "-20%",
      action: "Add To Cart",
    },
    {
      imgSrc: "assets/img/product/product2.jpg",
      name: "Simple Rounded Sunglasses",
      currentPrice: "₹300",
      oldPrice: "₹500",
      rating: 14,
      discount: "-20%",
      action: "Add To Cart",
    },
  ];

  // Pagination state
  const [currentPage, setCurrentPage] = useState(0);
  const productsPerPage = 6;
  const offset = currentPage * productsPerPage;
  const currentProducts = products.slice(offset, offset + productsPerPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <div>
      {/* Breadcrumbs */}
      <nav aria-label="breadcrumb" id="headerspace">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Products
          </li>
        </ol>
      </nav>

      <div className="product-card-wrapper container">
        <div className="row">
          {currentProducts.map((product, index) => (
            <div className="col-md-3" key={index}>
              <div className="single_product">
                <figure>
                  <div className="product_thumb">
                    <Link to="/Productdetail">
                      <img
                        className="primary_img"
                        src={product.imgSrc}
                        alt={product.name}
                      />
                    </Link>
                    <div className="product_action">
                      <ul>
                        <li className="wishlist">
                          <Link to="/Favourite" title="Wishlist">
                            <i className="icon-heart icons"></i>
                          </Link>
                        </li>
                        <li className="quick_view">
                          <a
                            data-toggle="modal"
                            data-target="#modal_box"
                            data-tippy="Quick View"
                            href="#"
                          >
                            <i className="icon-size-fullscreen icons"></i>
                          </a>
                        </li>
                        <li className="compare">
                          <Link data-tippy="Compare" to="/product">
                            <i className="icon-basket-loaded icons"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {product.discount && (
                      <div className="product_label">
                        <span>{product.discount}</span>
                      </div>
                    )}
                  </div>
                  <figcaption className="product_content text-center">
                    <div className="product_ratting">
                      <ul className="d-flex justify-content-center">
                        {[...Array(5)].map((_, i) => (
                          <li key={i}>
                            <a href="#">
                              <i
                                className={`ion-android-star${
                                  i < product.rating ? "" : "-outline"
                                }`}
                              ></i>
                            </a>
                          </li>
                        ))}
                        <li>
                          <span>({product.rating})</span>
                        </li>
                      </ul>
                    </div>
                    <h4 className="product_name">
                      <a href="product-details.html">{product.name}</a>
                    </h4>
                    <div className="price_box">
                      <span className="current_price">
                        {product.currentPrice}
                      </span>
                      {product.oldPrice && (
                        <span className="old_price">{product.oldPrice}</span>
                      )}
                    </div>
                    <div className="add_to_cart">
                      <Link
                        className="btn btn-primary"
                        to="/Cart"
                        data-tippy="Add To Cart"
                      >
                        {product.action}
                      </Link>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-center my-5">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={Math.ceil(products.length / productsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>

      <div
        className="modal fade"
        id="modal_box"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="ion-android-close"></i>
              </span>
            </button>
            <div className="modal_body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="modal_zoom_gallery">
                      <div className="product_zoom_thumb">
                        <img
                          src="assets/img/product/big-product/product1.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="modal_right">
                      <div className="modal_title mb-10">
                        <h2>Donec Ac Tempus</h2>
                      </div>
                      <div className="modal_price mb-10">
                        <span className="new_price">$64.99</span>
                        <span className="old_price">$78.99</span>
                      </div>
                      <div className="modal_description mb-15">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Mollitia iste laborum ad impedit pariatur esse
                          optio tempora sint ullam autem deleniti nam in quos
                          qui,
                        </p>
                      </div>
                      <div className="variants_selects">
                        <div className="variants_size">
                          <h2>size</h2>
                          <select className="select_option">
                            <option selected value="1">
                              s
                            </option>
                            <option value="1">m</option>
                            <option value="1">l</option>
                            <option value="1">xl</option>
                            <option value="1">xxl</option>
                          </select>
                        </div>
                        <div className="variants_color">
                          <h2>color</h2>
                          <select className="select_option">
                            <option selected value="1">
                              purple
                            </option>
                            <option value="1">violet</option>
                            <option value="1">black</option>
                            <option value="1">pink</option>
                            <option value="1">orange</option>
                          </select>
                        </div>
                        <div className="modal_add_to_cart">
                          <form action="#">
                            <input
                              min="1"
                              max="100"
                              step="2"
                              value="1"
                              type="number"
                            />
                            <button type="submit">add to cart</button>
                          </form>
                        </div>
                      </div>
                      <div className="modal_social">
                        <h2>Share this product</h2>
                        <ul>
                          <li className="facebook">
                            <a href="#">
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li className="twitter">
                            <a href="#">
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li className="pinterest">
                            <a href="#">
                              <i className="fa fa-pinterest"></i>
                            </a>
                          </li>
                          <li className="google-plus">
                            <a href="#">
                              <i className="fa fa-google-plus"></i>
                            </a>
                          </li>
                          <li className="linkedin">
                            <a href="#">
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
