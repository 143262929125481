import React from "react";
import { Link } from "react-router-dom";

const Minicart = ({ isOpen, onClose }) => {
  return (
    <div className={`minicart-header ${isOpen ? "show" : ""}`}>
      <div className="minicart-headermain">
        <div className="minicart-headermaintext">
          {" "}
          <h4> Cart</h4>
        </div>
        <div
          onClick={onClose}
          style={{ cursor: "pointer" }}
          className="minicart-headermainclose"
        >
          <i className="ion-android-close"></i>
        </div>
      </div>
      <hr></hr>
      <div className="minicart-main">
        <div className="minicart-inner">
          <div>
            <img src="./assets/img/product/product1.jpg" alt="img"></img>
          </div>
          <div>
            <p>Example With Title Product</p>
            {/* <p> Color : White </p>
            <p>Size : XL</p> */}
            <p>price: 300</p>
            <p>Quantity:1</p>
          </div>
        </div>
        <div>
          <i className="ion-android-close"></i>
        </div>
      </div>
      <hr></hr>
      <div className="minicart-main">
        <div className="minicart-inner">
          <div>
            <img src="./assets/img/product/product4.jpg" alt="img"></img>
          </div>
          <div>
            <p>Example With Title Product</p>
            {/* <p> Color : White </p>
            <p>Size : XL</p> */}
            <p>price: 300</p>
            <p>Quantity:1</p>
          </div>
        </div>
        <div>
          <i className="ion-android-close"></i>
        </div>
      </div>
      <hr></hr>

      <div className="place_order_btn">
        <Link className="btn btn-primary" to="/Cart" onClick={onClose}>
          View cart
        </Link>
      </div>
    </div>
  );
};

export default Minicart;
