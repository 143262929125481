import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Pages/Home";
import Product from "./Components/Pages/Product";
import "./App.css";
import Footer from "./Components/Mainpages/Footer";
import Header from "./Components/Mainpages/Header";
import Productdetail from "./Components/Pages/Productdetail";
import Cart from "./Components/Pages/Cart";
import Payment from "./Components/Pages/Payment";
import Notification from "./Components/Pages/Notification";
import Minicart from "./Components/Pages/Minicart";
import AuthForm from "./Components/Pages/AuthForm";
import Contact from "./Components/Pages/Contact";
import Favourite from "./Components/Pages/Favourite";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/Productdetail" element={<Productdetail />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Minicart" element={<Minicart />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Notification" element={<Notification />} />
        <Route path="/AuthForm" element={<AuthForm />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Favourite" element={<Favourite />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
